<template>
    <div class="Bill container container-fluid">
        <div class="w-100 row-menu-grid align-self-stretch">
            <div class="nav-top hide">
                <div class="col-nav">
                    <a @click="goHome" href="#">
                        <button class="btn ">
                            <i class="fas fa-chevron-left"></i>
                        </button>
                    </a>
                </div>
                <h3>โพยหวย</h3>
                <el-button 
                @click="exit"
                class="lotto-drawer-close-button" 
                icon="el-icon-close" 
                circle></el-button>
            </div>

                <div class="content-bill">
                    <div class="content-bill-info">
                        <div class="content-top-bill-menu">
                            <div class="col-12 col-lg-12">
                                <div class="row">
<!--                                    <div class="col"><h5>รายการโพย</h5></div>-->
                                    <div class="box-menu-lotto">
                                        <carousel
                                                :speed="300"
                                                :per-page="5"
                                                :mouseDrag="true"
                                                :spacePadding="1"
                                                :navigationEnabled="true"
                                                :paginationEnabled="false"
                                                navigationNextLabel="❯"
                                                navigationPrevLabel="❮"
                                                paginationPosition="bottom"
                                        >
                                            <slide :class="{'active': 'all' === lotto_type}" >
                                                <div @click="() => { lotto_type = 'all' }"  class="list-menu-lotto">
                                                    <div class="logo-all"></div>
                                                    <h6>ทั้งหมด</h6>
                                                </div>
                                            </slide>
                                            <slide v-for="(value, key) of lotto_type_map"
                                                   :key="key" :value="key"
                                                   :class="{'active': key === lotto_type}">
                                                <div class="list-menu-lotto"
                                                     @click="() => { lotto_type = key }">
                                                    <LottoTypeIcon :lotto_type="key" customClass="custom-list-menu-lotto"></LottoTypeIcon>
                                                    <h6>{{value}}</h6>
                                                </div>
                                            </slide>
                                        </carousel>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-top-bill">
                            <div class="content-top-bill-info">
                                <h5 class="bx-mobile">ยอดแทงวันนี้</h5>
                                <div class="content-all-today">
                                    <div class="col-all-today">
                                        <div class="txt-balance">{{ total_amount | floatFormat }} ฿</div>
                                        <p>ยอดรวม</p>
                                    </div>
                                    <div class="col-all-today col-all-today-haft">
                                        <div class="txt-balance">{{result_list.length}}</div>
                                        <p>ออกผลแล้ว</p>
                                    </div>
                                    <div class="col-all-today col-all-today-haft">
                                        <div class="txt-balance">{{not_result_list.length}}</div>
                                        <p>ยังไม่ออกผล</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-tab-list">
                            <b-tabs @input="(tab) => { bill_tab = tab }" v-model="bill_tab" :small="$isMobileTablet">
                                <b-tab title-item-class="v-b-tab-font" title="โพยทั้งหมด">
                                    <div class="content-tab-list-info">
                                            <div v-for="bill of bill_list" :key="bill.bill_id"
                                                    class="content-billbox">
                                                <BillBlock @click="seeDetail(bill)" :bill="bill"/>
                                            </div>
                                            <div v-if="bill_list.length === 0" class="v-no-result">ไม่มีรายการ</div>
                                    </div>
                                </b-tab>
                                <b-tab title-item-class="v-b-tab-font" title="ยังไม่ออกผล">
                                    <div class="content-tab-list-info">
                                            <div v-for="bill of not_result_list" :key="bill.bill_id"
                                                    class="content-billbox">
                                                <BillBlock @click="seeDetail(bill)" :bill="bill"/>
                                            </div>
                                            <div v-if="not_result_list.length === 0" class="v-no-result">ไม่มีรายการ</div>
                                    </div>
                                </b-tab>
                                <b-tab title-item-class="v-b-tab-font" title="ออกผลแล้ว">
                                    <div class="content-tab-list-info">
                                            <div v-for="bill of result_list" :key="bill.bill_id"
                                                    class="content-billbox">
                                                <BillBlock @click="seeDetail(bill)" :bill="bill"/>
                                            </div>
                                            <div v-if="result_list.length === 0" class="v-no-result">ไม่มีรายการ</div>
                                    </div>
                                </b-tab>
                                <b-tab title-item-class="v-b-tab-font" title="โพยก่อนหน้า">
                                    <div class="content-tab-list-info">
                                            <div v-for="bill of prev_bill_list" :key="bill.bill_id"
                                                    class="content-billbox">
                                                <BillBlock @click="seeDetail(bill)" :bill="bill"/>
                                            </div>
                                            <div v-if="prev_bill_list.length === 0" class="v-no-result">ไม่มีรายการ</div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>

        </div>
        <modal name="billDetailModal"
        :adaptive="true"
        height="100%"
        width="100%"
        :pivotY="1"
        transition="modal-slide-up" class="billDetail-Modal">
            <BillDetail :bill_id="selectedBillId" @close="() => {$modal.hide('billDetailModal')}"></BillDetail>
        </modal>
    </div>
</template>

<script>

import store from '@/store'
import AppMixin from './mixins/App'
import axiosWrapper from '@/axiosWrapper'
import BillBlock from "./components/BillBlock"
import {LOTTO_TYPE} from './Constant'
import BillDetail from './components/BillDetail'
import LottoTypeIcon from './components/LottoTypeIcon'
import { Carousel, Slide } from 'vue-carousel'

export default {
    name: "PageBill",
    mixins: [
        AppMixin,
    ],
    components: {
        BillBlock,
        BillDetail,
        LottoTypeIcon,
        Carousel,
        Slide,
    },
    data(){
        return {
            round_id: '',
            bill_list: [],
            prev_bill_list: [],
            lotto_type_map: null,
            lotto_type: 'all',
            bill_tab: 0,
            selectedBillId: '',
        }
    },
    computed: {
        is_member() {
            return store.state.is_member
        },
        user_profile() {
            return store.state.user_profile
        },
        pes_profile() {
            return store.state.pes_profile
        },
        total_amount: function () {
            if (this.bill_list.length > 0) {
                return this.bill_list.reduce((sum, bill) => {
                    return parseFloat(sum) + parseFloat(bill.amount);
                }, 0);
            } else {
                return 0;
            }
        },
        result_list: function () {
            if (this.bill_list) {
                return this.bill_list.filter((bill) => {
                    return bill.status === 'win' || bill.status === 'lose'
                });
            } else {
                return [];
            }
        },
        not_result_list: function () {
            if (this.bill_list) {
                return this.bill_list.filter((bill) => {
                    return bill.status === 'running'
                });
            } else {
                return [];
            }
        },
        token(){
            return this.pes_profile.token
        }
    },
    watch: {
        lotto_type: function (new_val) {
            if(new_val){
                this.loadAll();
            }
        },
    },
    methods: {
        loadLottoRound: async function () {
            if (this.lotto_type === 'yeekee') {
                return await this.round_get_latest_group();
            } else {
                return await this.round_get_latest();
            }
        },
        round_get_latest: async function () {
            const round_res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LOTTO_API}/api/round/get_latest`,
                data: {
                    number: 2,
                    lotto_type: this.lotto_type,
                }
            });
            if (round_res.status === 200) {
                return round_res.data;
            } else {
                this.$bvToast.toast(round_res.data.message, {title: 'แจ้งเตือน'});
            }
        },
        round_get_latest_group: async function () {
            const round_res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LOTTO_API}/api/round/get_latest_group`,
                data: {
                    limit: 2,
                    lotto_type: this.lotto_type,
                }
            });
            if (round_res.status === 200) {
                return round_res.data;
            } else {
                this.$bvToast.toast(round_res.data.message, {title: 'แจ้งเตือน'});
            }
        },
        loadData: async function (id_user, round) {
            if (round) {
                if (this.lotto_type === 'yeekee') {
                    return await this.load_data_by_date(id_user, round);
                } else {
                    return await this.load_data_by_id(id_user, round);
                }
            } else {
                return [];
            }
        },
        loadDataAll: async function (id_user, round_order) {

            let res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LOTTO_API}/api/bill/gets`,
                data: {
                    token: this.token,
                    lotto_type: 'all',
                    round_order: round_order,
                }
            });
            if (res.status === 200) {
                return res.data;
            } else {
                this.$bvToast.toast(res.data.message, {title: 'แจ้งเตือน'});
                return [];
            }
        },
        load_data_by_id: async function (id_user, round) {
            let res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LOTTO_API}/api/bill/gets`,
                data: {
                    token: this.token,
                    round_id: round.id,
                }
            });
            if (res.status === 200) {
                return res.data;
            } else {
                return [];
            }
        },
        load_data_by_date: async function (id_user, round) {
            let res = await axiosWrapper({
                method: 'POST',
                url: `${this.envDomains.VUE_APP_LOTTO_API}/api/bill/gets`,
                data: {
                    token: this.token,
                    by_column: 'date',
                    date: round.date,
                    lotto_type: this.lotto_type,
                }
            });
            if (res.status === 200) {
                return res.data;
            } else {
                return [];
            }
        },
        loadAll: async function () {

            if (this.lotto_type === 'all') {
                this.bill_list = await this.loadDataAll(this.user_profile.id, 0);
                this.prev_bill_list = await this.loadDataAll(this.user_profile.id, 1);
            }else{
                const lotto_round_list = await this.loadLottoRound();
                this.bill_list = await this.loadData(this.user_profile.id, lotto_round_list[0]);
                this.prev_bill_list = await this.loadData(this.user_profile.id, lotto_round_list[1]);
            }
        },
        seeDetail(bill) {
            this.selectedBillId = bill.bill_id
            this.$modal.show('billDetailModal')
        }
    },
    created() {
        this.lotto_type_map = LOTTO_TYPE;
    },
    mounted: async function () {
        this.loadPriceTable()
        this.loadRewardLimit()
        this.loadAll();
    }
};
</script>

<style src="@/assets/css/lotto/reset.css"></style>
<style src="@/assets/css/lotto/style.css"></style>
<style src="@/assets/css/lotto/money.css"></style>
<style src="@/assets/css/lotto/bet.css"></style>
<style>
    .ViewBillLotto .box-txt{display: none}
    .ViewBillLotto .box-nav-menu {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    .ViewBillLotto .box-menu-top {
        justify-content: flex-end;
    }
</style>
<style scoped>

    .nav-top .text-right {
        margin-right: 44px;
    }
    .nav-top h3{
        position: absolute;
        font: 800 18px Athiti;
        top: -38px;
        left: 48px;
    }
    .nav-top{width: 100%; height: auto; border: 0;}
    .nav-top .col-nav{
        position: absolute;
        top: -50px;
        z-index: 3;
    }
    .nav-top .row-balance{display: none}
    .nav-top .el-button.is-circle{display: none}
    .nav-top .text-right {
        margin-right: 44px;
    }

</style>
<style>

    .v-no-result {
        width: 100%;
        margin: 10px 0;
        text-align: center;
    }
    .modal-slide-up-enter-active,
    .modal-slide-up-leave-active {
        transition: all 0.4s;
    }

    .modal-slide-up-enter,
    .modal-slide-up-leave-active {
        opacity: 0;
        transform: translateY(100vh);
    }
</style>
