<template>

    <div @click="$emit('click')" class="BillBlock card border-box" style="cursor: pointer;">
        <div v-if="!$isMobileTablet" class="hover-bill">
            <div class="hover-bill-icon"><i class="fas fa-search"></i></div>
        </div>
        <span :class="`status-poy ${statusClass}`">{{bill.status | lottoStatusDisplay}}</span>
        <div class="header-bill-side">
            <div class="left-detail-side">
                <LottoTypeIcon :lotto_type="bill.lotto_type" customClass="custom-bill-block"></LottoTypeIcon>
            </div>
            <div class="detail">
                <h5>{{ bill.lotto_type | lottoTypeDisplay }} {{bill.sub_round | sub_round_display}}</h5>
                <span>งวดประจำวันที่ : {{ toMoment(bill.round_str) | dateTimeFormat('DD/MM/YYYY') }}</span>
                <div class="p-card">
                    โพยเลขที่ #{{bill.bill_id}}
                </div>
                <span>วันที่ทำรายการ : {{ bill.created_at | dateTimeFormat('YYYY-MM-DD HH:mm') }}</span>
            </div>
        </div>

        <div class="">
            <table class="table-poy">
                <tr>
                    <td colspan="2">
<!--                        <ul>-->
<!--                            <li></li>-->
<!--                            <li></li>-->
<!--                            <li>งวดประจำวันที่ : {{bill.round_str}}</li>-->
<!--                        </ul>-->

                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="text-info">฿ {{ bill.amount | floatFormat }}</div>
                        <small>เงินเดิมพัน</small>
                    </td>
                    <td>
                        <div :class="`fa-pull-right ${resultClass}`">฿ {{ bill.result | floatFormat }}</div>
                        <small>ผลแพ้/ชนะ</small>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>

import AppMixin from '../mixins/App'
import LottoTypeIcon from './LottoTypeIcon'

export default {
    name: "BillBlock",
    mixins: [
        AppMixin,
    ],
    components: {
        LottoTypeIcon,
    },
    props: [
        'bill'
    ],
    computed: {
        statusClass: function(){
            if(this.bill){
                if(this.bill.status === 'running'){
                    return 'bg-warning';
                }else if(this.bill.status === 'lose'){
                    return 'bg-danger text-white';
                }else if(this.bill.status === 'win'){
                    return 'bg-success text-white';
                }else if(this.bill.status === 'cancel'){
                    return 'bg-secondary text-white';
                }else {
                    return 'bg-secondary text-white';
                }
            }else{
                return 'bg-secondary text-white';
            }
        },
        resultClass: function(){
            if(this.bill){
                if(this.bill.status === 'running'){
                    return '';
                }else if(this.bill.status === 'lose'){
                    return 'text-danger';
                }else if(this.bill.status === 'win'){
                    return 'text-success';
                }else if(this.bill.status === 'cancel'){
                    return '';
                }else {
                    return '';
                }
            }else{
                return '';
            }
        }
    },
    methods: {
        toMoment: function(val){
            return this.$moment(val);
        },
    },
}
</script>

<style scoped>
  .border-box{
      border-color: #ccc !important;
      border-radius: 15px;
  }

  .bg-warning {
      /*background-color: #f5ba62 !important;*/
  }

    .v-sub-round {
        display: inline-block;
        width: 100%;
        font-size: 14px;
        font-weight: 800;
    }
</style>