import { render, staticRenderFns } from "./Bill.vue?vue&type=template&id=a1bf637e&scoped=true&"
import script from "./Bill.vue?vue&type=script&lang=js&"
export * from "./Bill.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/lotto/reset.css?vue&type=style&index=0&lang=css&"
import style1 from "@/assets/css/lotto/style.css?vue&type=style&index=1&lang=css&"
import style2 from "@/assets/css/lotto/money.css?vue&type=style&index=2&lang=css&"
import style3 from "@/assets/css/lotto/bet.css?vue&type=style&index=3&lang=css&"
import style4 from "./Bill.vue?vue&type=style&index=4&lang=css&"
import style5 from "./Bill.vue?vue&type=style&index=5&id=a1bf637e&scoped=true&lang=css&"
import style6 from "./Bill.vue?vue&type=style&index=6&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1bf637e",
  null
  
)

export default component.exports