<template>

    <div class="wrapContentIndex wrapContentIndexLotto wrapContentIndexLottoBet">
<!--    <div class="ViewBill ViewBillLotto">-->
        <div class="wrapContentIndexTitle">
            <button style="padding: 0 10px;" class="btn " @click="goHome()"><i class="fas fa-chevron-left"></i></button>
            <h3>โพยหวย</h3>
        </div>
        <div class="navTopDesktop">
            <!-- <MenuNavTop  @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNavTop> -->
        </div>
        <!-- <FloatButtomRightPanel  :callChatWithAdmin="showChatWithAdminNumber"/> -->
        <MenuBar :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber"></MenuBar>
        <div class="wrapContentIndexInfo">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexLeft active">
                    <MenuLeft></MenuLeft>
                </div>
                <div class="contentFlexIndexRight">
                    <!--                    <div class="contentFlexIndexRightInfo">-->
                    <div class="container">
                        <div class="containerGameList">
                            <div class="containerGameListinfo" >
                                <Bill></Bill>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

import Bill from '@/components/lotto/Bill'
import MenuBar from '@/components/MenuBar'
// import FloatButtomRightPanel from '@/components/FloatButtomRightPanel'
// import MenuNavTop from '@/components/MenuNavTop'
import MenuLeft from '@/components/MenuLeft'


export default {
    name: 'ViewBill',
    components: {
        // FloatButtomRightPanel,
        Bill,
        MenuBar,
        MenuLeft,
        // MenuNavTop,
    },
    data(){
        return {
            menuNumber :0,
        }

    },
    methods: {
        showMenuNav(menuNumber){
                this.menuNumber = menuNumber;
                let self =this;
                setTimeout(()=>{
                    self.menuNumber=0;
                },1000);
        },
        goHome() {
            this.$router.push({name: 'IndexLotto'})
        },
    }
}
</script>
<style>
    /*.ViewBillLotto .MenuBar.box-all-games{display: none!important}*/
    .ViewBillLotto span.box-txt-back{display: none}
    .ViewBillLotto .wrap-menu-top{z-index: 1}
    .ViewBillLotto .VueCarousel-navigation-button{z-index: 0}
    @media only screen
    and (max-device-width: 1300px)
    and  (orientation: landscape)  {
        /*.ViewBillLotto .MenuBar.box-all-games{display: block!important}*/
        .ViewBillLotto .box-menu-lotto{
            justify-content: flex-start!important;
        }
        /*.ViewBillLotto .wrap-menu-top{*/
        /*    padding: 5px 5px 40px 5px!important;*/
        /*}*/
        /*.ViewBillLotto .nav-top .col-nav {*/
        /*    top: 0;*/
        /*}*/
    }
    @media only screen
    and (min-device-width: 500px)
    and (max-device-width: 700px)
    and (orientation: landscape) {
        /*.ViewBillLotto .wrap-menu-top{*/
        /*    padding: 5px 5px 40px 5px;*/
        /*}*/
    }

</style>
<style scoped>
    .ViewBillLotto{
        background-image: linear-gradient(
                135deg
                , #F372D4 10%, #c765f0 100%);
    }
    .ViewBillLotto .wrap-menu-top{
        padding: 10px 5px 10px 5px;
        background-image: none;
    }
    @media only screen
    and (max-device-width: 1300px)
    and  (orientation: landscape){
        /*.ViewBillLotto {padding: 0 5px 0 355px;}*/
    }
    @media only screen
    and (min-device-width: 500px)
    and (max-device-width: 700px)
    and (orientation: landscape) {
        /*.ViewBillLotto {padding: 0 5px 0 285px;}*/
    }

</style>